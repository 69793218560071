import React, { useState } from "react";
//import pdfcv from "../../../assets/Rosella_CV.pdf";
//import { pdfRef, getDownloadURL } from "../../../firebase/Firebase";
//import { Document, Page, pdfjs } from "react-pdf";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import { saveAs } from "file-saver";
//import DocumentPage from "./Document";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import {
  Button,
  ButtonGroup,
  Stack,
  Card,
  Chip,
  Tooltip,
  //  Skeleton,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import ContactPage from "./Contacts";
import newPdf from "../../../assets/Rosella_CV.pdf";
import classes from "./CurriCulumPage.module.css";
//pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const { innerWidth: width } = window;
const pdfWidth = width * 0.8;

const CurriculumPage = () => {
  // const [newPdf, setNewPdf] = useState(null);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  // const [error, setError] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  // const isDesktop = useMediaQuery(theme.breakpoints.up("xl"));

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const changePage = (offset) => {
    setPageNumber((prevNum) => prevNum + offset);
  };

  const previousPage = () => {
    changePage(-1);
  };

  const nextPage = () => {
    changePage(1);
  };

  const downloadPdf = () => {
    //    try {
    //      const response = await fetch(newPdf);
    //      const pdfToBlob = await response.blob();
    saveAs(newPdf, "rosella_cv.pdf");
  };
  //} catch (er) {
  //      console.log(er.code);
  //    }
  //  };

  const currentPageCounter = (pageNumber || (numPages ? 1 : "--")).toString();
  const totalPageCounter = (numPages || "--").toString();
  const pageCounter = currentPageCounter + "/" + totalPageCounter;

  //  const getPdf = useCallback(async () => {
  //    try {
  //      const pdfUrl = await getDownloadURL(pdfRef);
  //      setNewPdf(pdfUrl);
  //    } catch (er) {
  //      console.log(er.code);
  //      setError({ message: "No pdf Found" });
  //    }
  //  }, []);

  //  useEffect(() => {
  //    getPdf();
  //    return () => {};
  //  }, [getPdf]);

  return (
    <Stack
      direction={{ xs: "column", sm: "column", lg: "row" }}
      spacing={2}
      className={classes.page}
    >
      <Card className={classes.cv}>
        <Stack direction="row" spacing={3}>
          <ButtonGroup
            aria-label="outlined primary button group"
            variant="contained"
          >
            <Button disabled={pageNumber <= 1} onClick={previousPage}>
              <ChevronLeftIcon />
            </Button>
            <Tooltip title="Download" placement="top">
              <Button
                aria-label="download cv"
                color="primary"
                disabled={!newPdf}
                onClick={downloadPdf}
              >
                <FileDownloadIcon />
              </Button>
            </Tooltip>
            <Button disabled={pageNumber >= numPages} onClick={nextPage}>
              <ChevronRightIcon />
            </Button>
          </ButtonGroup>
          <Chip variant="filled" label={pageCounter} />
        </Stack>
        <div className={classes.cvContainer}>
          <Document
            file={newPdf}
            onLoadSuccess={onDocumentLoadSuccess}
            renderMode="canvas"
          >
            {!isMobile ? (
              <Page className={classes.doc} pageNumber={pageNumber} />
            ) : (
              <Page
                className={classes.doc}
                pageNumber={pageNumber}
                width={pdfWidth}
              />
            )}
          </Document>
        </div>
      </Card>
      <ContactPage className={classes.contact} />
    </Stack>
  );
};

export default React.memo(CurriculumPage);

//      <Card className={classes.cvSk}>
//        <Skeleton className={classes.cvSk} />
//      </Card>
//      <Card className={classes.cv}>
