import React, { Fragment } from "react";
import {
  Typography,
  ListItem,
  ListItemText,
  Divider,
  Tooltip,
} from "@mui/material";

export const WorkItem = (props) => {
  if (props.link) {
    return (
      <Fragment>
        <ListItem
          onClick={() => {
            window.open(props.link, "_blank");
          }}
        >
          <Tooltip placement="top-start" title="Click and check!">
            <ListItemText
              primary={
                <Typography component={"span"} variant="h6">
                  {props.title}
                </Typography>
              }
              secondary={
                <Fragment>
                  <Typography
                    sx={{ fontStyle: "italic" }}
                    component="span"
                    variant="body"
                  >
                    {props.journal + " "}
                  </Typography>
                  <Typography component="span" variant="body">
                    {props.description + " "}
                  </Typography>
                  <Typography component="span" variant="body2">
                    {props.date}
                  </Typography>
                </Fragment>
              }
            />
          </Tooltip>
        </ListItem>
        <Divider />
      </Fragment>
    );
  } else {
    return (
      <Fragment>
        <ListItem>
          <ListItemText
            primary={
              <Typography component={"span"} variant="h6">
                {props.title}
              </Typography>
            }
            secondary={
              <Fragment>
                <Typography
                  sx={{ fontStyle: "italic" }}
                  component="span"
                  variant="body"
                >
                  {props.journal + " "}
                </Typography>
                <Typography component="span" variant="body">
                  {props.description + "  "}
                </Typography>
                <Typography component="span" variant="body2">
                  {props.date}
                </Typography>
              </Fragment>
            }
          />
        </ListItem>
        <Divider />
      </Fragment>
    );
  }
};

//export const TeachItem = (props) => {
//  return (
//    <ListItem>
//      <ListItemText
//        primary={<Typography variant="h6">{props.title}</Typography>}
//        secondary={
//          <Fragment>
//            <Typography component="span" variant="subtitle2">
//              {props.description + " , " + props.date}
//            </Typography>
//            <Divider />
//          </Fragment>
//        }
//      />
//    </ListItem>
//  );
//};
