import React, { useState, useEffect, Fragment, useCallback } from "react";
import { talksDb, get } from "../../../firebase/Firebase";
import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  Divider,
  List,
  Skeleton,
} from "@mui/material";
import TalksItem from "./TalksItem";
import talkImg from "../../../assets/talk_image.jpg";
//import { talkImgRef, getDownloadURL } from "../../../firebase/Firebase";
import classes from "./Talks.module.css";

const currentTime = new Date();

const TalkPage = () => {
  //  const [talkImg, setTalkImg] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  const [upcomingTalksList, setUpcomingTalksList] = useState([]);
  const [recentTalksList, setRecentTalksList] = useState([]);
  const dateHelper = (item) => {
    if (new Date(item.date) instanceof Date && item.date) {
      const stringToDate = item.date
        .split(/\s*,\s*/)
        .map((num) => parseInt(num));
      return { ...item, date: new Date(...stringToDate) };
    } else {
      return item;
    }
  };

  //  const getTalkImg = useCallback(async () => {
  //    try {
  //      const talkImgUrl = await getDownloadURL(talkImgRef);
  //      setTalkImg(talkImgUrl);
  //    } catch (er) {
  //      console.log(er.code);
  //    }
  //  }, []);

  //          onValue(talksDb, (snapshot)=>{
  //
  //          })
  const fetchTalks = useCallback(async () => {
    try {
      const data = await get(talksDb);
      if (data.exists()) {
        let talkData = data.val();
        const talksWithDates = talkData.map((item) => dateHelper(item));
        const upcomingTalks = new Set(
          talksWithDates.filter(
            (talk) => currentTime < talk.date || talk.isRecent
          )
        );
        const unSetUpcomingTalks = Array.from(upcomingTalks);

        //const recentTalksUnsorted = talksWithDates.filter(
        //  (talk) => talk.date < currentTime || !talk.isRecent
        //);
        const recentTalksUnsorted = talksWithDates.filter(
          (x) => !upcomingTalks.has(x)
        );
        const recentTalks = recentTalksUnsorted.sort((a, b) => b.date - a.date);
        setUpcomingTalksList(unSetUpcomingTalks);
        //        console.log(upcomingTalksList);
        setRecentTalksList(recentTalks);
        setIsLoading(false);
      } else {
        throw new Error("No data available");
      }
    } catch (error) {
      setError({ message: error });
      setIsLoading(false);
    }
  }, []);

  const upComingTalksMap = upcomingTalksList.map((item) => (
    <TalksItem
      title={item.title}
      host={item.host}
      isComing={true}
      country={item.country}
      event={item.event}
      date={item.date}
      type={item.type}
      link={item.link}
      key={item.title + item.event + item.host}
    />
  ));

  const pastTalksMap = recentTalksList.map((item) => (
    <TalksItem
      isComing={false}
      title={item.title}
      key={item.title + item.event + item.host}
      host={item.host}
      date={item.date}
      country={item.country}
      event={item.event}
      type={item.type}
      link={item.link}
    />
  ));

  useEffect(() => {
    //const abort = new AbortController();
    //    getTalkImg();
    fetchTalks();
    return () => {};
  }, [fetchTalks]);

  return (
    <Card style={{ backgroundColor: "white" }} className={classes.talkCard}>
      <CardMedia
        component="img"
        image={talkImg}
        alt="talk image"
        className={classes.img}
      />
      <CardContent>
        <Typography sx={{ fontWeight: "bold" }} variant="h3" gutterBottom>
          A List of my Talks
        </Typography>
        <Divider />
        <Fragment>
          {upcomingTalksList && upcomingTalksList.length > 0 && (
            <Typography
              className={classes.title}
              sx={{ fontWeight: "bold" }}
              variant="h4"
              gutterBottom
            >
              Upcoming
            </Typography>
          )}
          <List className={classes.list}>
            {error && !isLoading && (
              <Typography variant="body">{error.message}</Typography>
            )}
            {isLoading && !error && (
              <Fragment>
                <Skeleton /> <Skeleton />
              </Fragment>
            )}
            {!error &&
              !isLoading &&
              upcomingTalksList &&
              upcomingTalksList.length > 0 &&
              (upComingTalksMap ? upComingTalksMap : "Error When Loading Data")}
          </List>
          {upcomingTalksList && upcomingTalksList.length > 0 && <Divider />}
        </Fragment>
        <Typography
          className={classes.title}
          sx={{ fontWeight: "bold" }}
          variant="h4"
          gutterBottom
        >
          Past
        </Typography>
        <Fragment>
          <List className={classes.list}>
            {error && <Typography variant="h6">error</Typography>}
            {isLoading && (
              <Fragment>
                <Skeleton />
                <Skeleton /> <Skeleton />
              </Fragment>
            )}

            {!error &&
              !isLoading &&
              recentTalksList &&
              recentTalksList.length > 0 &&
              (pastTalksMap ? pastTalksMap : "Error When Loading Data")}
          </List>
        </Fragment>
      </CardContent>
    </Card>
  );
};

export default React.memo(TalkPage);
//                date={item.date}
//                  date={item.date}
