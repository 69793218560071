import React, { Fragment } from "react";
import {
  Typography,
  ListItemIcon,
  ListItem,
  ListItemText,
  Tooltip,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

const TalksItem = (props) => {
  let itemDate;
  new Date(props.date) instanceof Date && props.date
    ? (itemDate = props.date.toDateString())
    : (itemDate = "Date TBD");
  if (props.link) {
    return (
      <Tooltip placement="top-start" title="Click and check!">
        <ListItem
          onClick={() => {
            window.open(props.link, "_blank");
          }}
        >
          <ListItemIcon>
            {!props.isComing ? <CheckIcon /> : <AccessTimeIcon />}
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography component={"span"} variant="h6">
                {props.title}
              </Typography>
            }
            secondary={
              <Fragment>
                <Typography
                  component={"span"}
                  sx={{ fontStyle: "italic" }}
                  variant="body"
                  color="text.primary"
                >
                  {props.event}
                </Typography>
                <Typography component={"span"} variant="body2">
                  {" - " +
                    props.host +
                    " , " +
                    props.country +
                    " , " +
                    itemDate +
                    " " +
                    props.type}
                </Typography>
              </Fragment>
            }
          />
        </ListItem>
      </Tooltip>
    );
  } else {
    return (
      <ListItem key={props.title + props.event + itemDate}>
        <ListItemIcon>
          {!props.isComing ? <CheckIcon /> : <AccessTimeIcon />}
        </ListItemIcon>
        <ListItemText
          primary={
            <Typography component={"span"} variant="h6">
              {props.title}
            </Typography>
          }
          secondary={
            <Fragment>
              <Typography
                variant="body"
                sx={{ fontStyle: "italic" }}
                component={"span"}
                color="text.primary"
              >
                {props.event}
              </Typography>
              <Typography component={"span"} variant="body2">
                {" - " +
                  props.host +
                  " , " +
                  props.country +
                  " , " +
                  itemDate +
                  "  " +
                  props.type}
              </Typography>
            </Fragment>
          }
        />
      </ListItem>
    );
  }
};
export default TalksItem;
