//import React, { useState, useEffect } from "react";
import {
  //  Stack,
  Card,
  Typography,
  Link,
  Divider,
  CardMedia,
  CardContent,
  //  Skeleton,
} from "@mui/material";
//import { rosellaImgRef, getDownloadURL } from "../../firebase/Firebase";
import giuPhoto from "../../assets/rosella.jpg";
import classes from "./Home.module.css";

const HomePage = () => {
  //  const [giuPhoto, setGiuPhoto] = useState(false);
  //  const [error, setError] = useState(false);
  //  const [isLoading, setIsLoading] = useState(false);
  //
  //  useEffect(() => {
  //    const getImg = async () => {
  //      try {
  //        setIsLoading(true);
  //        const imgUrl = await getDownloadURL(rosellaImgRef);
  //        setGiuPhoto(imgUrl);
  //        setIsLoading(false);
  //      } catch (er) {
  //        console.log(er.code);
  //        setError({ message: "could not fetch image" });
  //        setGiuPhoto({ error: true });
  //      }
  //    };
  //    getImg();
  //    return () => {};
  //  }, []);
  //
  return (
    <Card style={{ backgroundColor: "white" }} className={classes.page}>
      <div className={classes.stack}>
        <CardMedia
          className={classes.img}
          component="img"
          alt="Giuliano Rosella photo"
          image={giuPhoto}
          style={{ backgroundColor: "white" }}
          sx={{
            height: ["12rem", "20rem"],
            width: ["12rem", "20rem"],
          }}
        />
        <div className={classes.title}>
          <Typography sx={{ fontWeight: "bold" }} variant="h3">
            Giuliano Rosella
          </Typography>
        </div>
      </div>
      <Divider sx={{ padding: "1em" }} />
      <CardContent>
        <div className={classes.text}>
          <Typography variant="h4">Hello!</Typography>
          <Typography variant="body">
            <p>
              {" "}
              I am a Ph. D. Student in Philosophy at the University of Turin,
              within the{" "}
              <Link
                href="https://www.finophd.eu/"
                target="_blank"
                rel="noopener"
              >
                FINO-Northwestern Italian Philosophy Consortium
              </Link>
              .
            </p>
            <p>
              Before joining the University of Turin, I was a student of the{" "}
              <Link
                href="https://msclogic.illc.uva.nl/"
                target="_blank"
                rel="noopener"
              >
                Master of Logic
              </Link>{" "}
              at the{" "}
              <Link
                href="https://www.illc.uva.nl/"
                target="_blank"
                rel="noopener"
              >
                ILLC-Institute for Logic, Language and Computation,{" "}
              </Link>
              University of Amsterdam, where I received my Master Degree in
              2019.
            </p>
            <p>
              My research mainly focuses on Logic and Philosophical Logic, in
              particular on Truthmaker Semantics and its applications. I am also
              interested in Causation and the Logic of Causal Inference.
            </p>
          </Typography>
        </div>
      </CardContent>
    </Card>
  );
};

export default HomePage;
//          <Skeleton
//            variant="rectangular"
//            className={classes.img}
//            sx={{
//              borderRadius: [7, 50],
//              height: ["12rem", "15rem"],
//              width: ["15rem", "20rem"],
//              paddingTop: ["1rem", "auto"],
//            }}
//          />
