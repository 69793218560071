import React, { useCallback, useEffect, useState } from "react";
import { Card, CardContent, Typography, List, Skeleton } from "@mui/material";
import { pubDb, teachDb, get } from "../../../firebase/Firebase";
import { WorkItem } from "./WorkItems";
import classes from "./Works.module.css";

const WorkPage = () => {
  const [publicationList, setPublicationList] = useState([]);
  const [teachingList, setTeachingList] = useState([]);
  const [error, setError] = useState(false);

  const [isLoading, setIsloading] = useState(true);

  const fetchList = useCallback(async (list) => {
    try {
      switch (list) {
        case "publications":
          const data = await get(pubDb);
          if (data.exists()) {
            const pubData = data.val();
            setPublicationList(pubData);
            setIsloading(false);
          } else {
            throw new Error("No data available");
          }
          break;
        case "teaching":
          const teachData = await get(teachDb);
          if (teachData.exists()) {
            const teachList = teachData.val();
            setTeachingList(teachList);
            setIsloading(false);
          } else {
            throw new Error("No data available");
          }
          break;
        default:
          console.log("something wrong with fetchList arg");
      }
    } catch (e) {
      setIsloading(false);
      setError({ message: e });
      console.log(e);
    }
  }, []);

  useEffect(() => {
    //    const abort = new AbortController();
    fetchList("publications");
    fetchList("teaching");
    return () => {};
  }, [fetchList]);

  return (
    <Card style={{ backgroundColor: "white" }} className={classes.workCard}>
      <CardContent>
        <Typography variant="h4" sx={{ fontWeight: "bold" }} gutterBottom>
          Publications
        </Typography>
        <List>
          {error.message}
          {isLoading && !error && <Skeleton />}
          {!isLoading &&
            !error &&
            publicationList.map((item) => (
              <WorkItem
                key={item.title + item.date + item.description}
                title={item.title}
                date={item.date}
                link={item.link}
                journal={item.journal || ""}
                description={item.description}
              />
            ))}
        </List>
        <Typography
          sx={{ fontWeight: "bold" }}
          className={classes.teachTitle}
          variant="h4"
          gutterBottom
        >
          Teaching
        </Typography>
        <List>
          {error.message}
          {isLoading && !error && <Skeleton />}
          {!isLoading &&
            !error &&
            teachingList.map((item) => (
              <WorkItem
                key={item.title + item.date + item.description}
                title={item.title}
                date={item.date}
                journal={item.journal || ""}
                link={item.link || false}
                description={item.description}
              />
            ))}
        </List>
      </CardContent>
    </Card>
  );
};

export default React.memo(WorkPage);
