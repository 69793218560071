import { Fragment } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  Tabs,
  Tab,
  AppBar,
  useScrollTrigger,
  Slide,
  // useTheme,
  //  useMediaQuery,
} from "@mui/material";
import classes from "./NavBar.module.css";
//import backgImg from "../../assets/math_back2.jpg";

const paths = [
  { id: "p0", label: "About me", to: "/Home", value: "/Home" },
  {
    id: "p1",
    label: "Works",
    to: "/Works",
    value: "/Works",
  },
  {
    id: "p2",
    label: "Talks",
    to: "/Talks",
    value: "/Talks",
  },
  {
    id: "p3",
    label: "CV and Contacts",
    to: "/CV",
    value: "/CV",
  },
];

function HideOnScroll(props) {
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger();

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {props.children}
    </Slide>
  );
}

const NavBar = (props) => {
  //  const theme = useTheme();
  const location = useLocation();
  return (
    <Fragment>
      <header>
        <HideOnScroll {...props}>
          <AppBar className={classes.bar}>
            <Tabs
              className={classes.tabs}
              value={location.pathname}
              indicatorColor="secondary"
              textColor="inherit"
              variant="fullWidth"
            >
              <Tab
                component={Link}
                label={paths[0].label}
                to={paths[0].to}
                value={paths[0].value}
              />
              <Tab
                component={Link}
                to={paths[1].to}
                label={paths[1].label}
                value={paths[1].value}
              />
              <Tab
                component={Link}
                to={paths[2].to}
                label={paths[2].label}
                value={paths[2].value}
              />
              <Tab
                component={Link}
                to={paths[3].to}
                label={paths[3].label}
                value={paths[3].value}
              />
            </Tabs>
          </AppBar>
        </HideOnScroll>
      </header>
    </Fragment>
  );
};

export default NavBar;
//
//<HideOnScroll {...props}>
//  <AppBar className={classes.bar}>
//    <Toolbar>
//      <div className={classes.navlinks}>
//        <Link to="/Home">Home</Link>
//        <Link to="/Works">About</Link>
//        <Link to="/Talks">Contact</Link>
//        <Link to="/CV and Contacts">FAQ</Link>
//      </div>
//    </Toolbar>
//  </AppBar>
//</HideOnScroll>
