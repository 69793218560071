import React, { Suspense } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material";
import LoadingSpinner from "./components/Layout/LoadingSpinner";
import HomePage from "./components/Pages/Home";
import Layout from "./components/Layout/Layout";
import TalkPage from "./components/Pages/Talks/Talks";
import CurriculumPage from "./components/Pages/Curriculum/CurriculumPage";
import WorkPage from "./components/Pages/Works/Works";
import theme from "./assets/theme";
import NotFound from "./components/Pages/NotFound";

//const Layout = React.lazy(() => import("./components/Layout/Layout"));
////const HomePage = React.lazy(() => import("./components/Pages/Home"));
////const NavBar = React.lazy(() => import("./components/Layout/NavBar"));
//const TalkPage = React.lazy(() => import("./components/Pages/Talks/Talks"));
//const CurriculumPage = React.lazy(() =>
//  import("./components/Pages/Curriculum/CurriculumPage")
//);
//const WorkPage = React.lazy(() => import("./components/Pages/Works/Works"));
//const NotFound = React.lazy(() => import("./components/Pages/NotFound"));

const MUtheme = createTheme(theme);

function App() {
  return (
    <ThemeProvider theme={MUtheme}>
      <Suspense
        fallback={
          <div className="centered">
            <LoadingSpinner />
          </div>
        }
      >
        <Layout>
          <Switch>
            <Route path="/" exact>
              <Redirect to="/Home" />
            </Route>
            <Route path="/Home" exact>
              <HomePage />
            </Route>
            <Route path="/Works" exact>
              <WorkPage />
            </Route>
            <Route path="/Talks" exact>
              <TalkPage />
            </Route>
            <Route path="/CV" exact>
              <CurriculumPage />
            </Route>
            <Route path="*">
              <NotFound />
            </Route>
          </Switch>
        </Layout>
      </Suspense>
    </ThemeProvider>
  );
}

export default App;

//        <Route
//          path="/"
//          render={({ location }) => (
//            <Fragment>
//        //
//        //
//        //
//            </Fragment>
//          )}
//        />
