const theme = {
  palette: {
    primary: {
      //      main: "#5e81ac",
      main: "#2e3440",
      contrastText: "#e5e9f0",
    },
    secondary: {
      //      main: "#88c0d0",
      main: "#88c0d0",
    },
    background: {
      default: "#eceff4",
      paper: "#eceff4",
    },
    text: {
      primary: "#2e3440",
      secondary: "#3b4252",
      disabled: "#434c5e",
      hint: "#4c566a",
    },
    error: {
      main: "#bf616a",
    },
    warning: {
      main: "#ebcb8b",
    },
    info: {
      main: "#b48ead",
    },
    success: {
      main: "#a3be8c",
    },
    divider: "#d8dee9",
  },
};

export default theme;
//  palette: {
//    type: "light",
//    primary: {
//      main: "#3b4252",
//      light: "#434c5e",
//      dark: "#2e3440",
//      contrastText: "#e5e9f0",
//    },
//    secondary: {
//      main: "#88c0d0",
//      light: "#81a1c1",
//      dark: "#5e81ac",
//      contrastText: "#e5e9f0",
//    },
//    background: {
//      default: "#eceff4",
//      paper: "#e5e9f0",
//    },
//    text: {
//      primary: "#4c566a",
//      secondary: "rgba(76,86,106,0.92)",
//      disabled: "rgba(76,86,106,0.79)",
//      hint: "rgba(76,86,106,0.63)",
//    },
//    error: {
//      main: "rgba(191,97,106,0.99)",
//      light: "rgba(191,97,106,0.96)",
//      dark: "#bf616a",
//      contrastText: "#eceff4",
//    },
//    warning: {
//      main: "#ebcb8b",
//      contrastText: "#2e3440",
//    },
//    info: {
//      main: "#b48ead",
//    },
//    success: {
//      main: "#a3be8c",
//    },
//  },
