// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getStorage, getDownloadURL, ref } from "firebase/storage";
import { getDatabase, ref as dbRef, get } from "firebase/database";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDkbHmVn0BUjfFjrCrmdRqrbCIs6Av5JbU",
  authDomain: "sito-di-giuliano.firebaseapp.com",
  databaseURL:
    "https://sito-di-giuliano-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "sito-di-giuliano",
  storageBucket: "gs://sito-di-giuliano.appspot.com/",
  messagingSenderId: "627207170161",
  appId: "1:627207170161:web:2d122a64ed7f8de85093d0",
};

// Initialize Firebase
const Firebase = initializeApp(firebaseConfig);

const storage = getStorage(Firebase);
const database = getDatabase(Firebase);

const talksDb = dbRef(database, "talks/");
const pubDb = dbRef(database, "publications/");
const teachDb = dbRef(database, "teaching/");

const pdfRef = ref(storage, "rosella_cv.pdf");
const rosellaImgRef = ref(storage, "rosella.jpg");
const talkImgRef = ref(storage, "talk_image.jpg");

export {
  Firebase,
  pdfRef,
  rosellaImgRef,
  talkImgRef,
  getDownloadURL,
  get,
  talksDb,
  pubDb,
  teachDb,
};
