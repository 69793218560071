import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import SchoolIcon from "@mui/icons-material/School";
import EmailIcon from "@mui/icons-material/Email";
import {
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Stack,
  Tooltip,
  Card,
} from "@mui/material";
import classes from "./Contacts.module.css";

const ContactPage = () => {
  return (
    <Card style={{ backgroundColor: "white" }} className={classes.page}>
      <Stack spacing={2}>
        <Typography sx={{ fontWeight: "bold" }} variant="h4" gutterBottom>
          Contacts
        </Typography>
        <List>
          {" "}
          <nav aria-label="main mailbox folders">
            <Tooltip title="Copy!" placement="top">
              <ListItem
                button
                onClick={() =>
                  navigator.clipboard.writeText("giuliano.rosella@unito.it")
                }
              >
                <ListItemIcon>
                  <EmailIcon />
                </ListItemIcon>
                <ListItemText primary="giuliano.rosella@unito.it" />
              </ListItem>
            </Tooltip>
            <Divider />
            <ListItem
              button
              onClick={() =>
                window.open(
                  "https://www.facebook.com/giuliano.rosella/",
                  "_blank"
                )
              }
            >
              <ListItemIcon>
                <FacebookIcon />
              </ListItemIcon>
              <ListItemText primary="Facebook" />
            </ListItem>
            <Divider />
            <ListItem
              button
              onClick={() =>
                window.open(
                  "https://it.linkedin.com/in/giuliano-rosella-463214140",
                  "_blank"
                )
              }
            >
              <ListItemIcon>
                <LinkedInIcon />
              </ListItemIcon>
              <ListItemText primary="LinkedIn" />
            </ListItem>
            <Divider />
            <ListItem
              button
              onClick={() =>
                window.open(
                  "https://unito.academia.edu/giulianorosella",
                  "_blank"
                )
              }
            >
              <ListItemIcon>
                <SchoolIcon />
              </ListItemIcon>
              <ListItemText primary="Academia" />
            </ListItem>
            <Divider />
            <ListItem
              button
              onClick={() =>
                window.open("https://www.finophd.eu/phd-students/122", "_blank")
              }
            >
              <ListItemIcon>
                <SchoolIcon />
              </ListItemIcon>
              <ListItemText primary="FINO Webpage" />
            </ListItem>
            <Divider />
          </nav>
        </List>
      </Stack>
    </Card>
  );
};

export default ContactPage;
