import { useState, Fragment } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import {
  Drawer,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Divider,
} from "@material-ui/core";
import { Link } from "react-router-dom";
//import classes from "./Drawer.module.css";

const DrawerBar = () => {
  const [openDrawer, setOpenDrawer] = useState(false);
  return (
    <Fragment>
      <Drawer open={openDrawer} onClose={() => setOpenDrawer(false)}>
        <List>
          <ListItem
            onClick={() => setOpenDrawer(false)}
            button
            component={Link}
            to="/"
          >
            <ListItemText>About Me</ListItemText>
            <Divider />
          </ListItem>
          <ListItem
            button
            component={Link}
            to="/Works"
            onClick={() => setOpenDrawer(false)}
          >
            <ListItemText>Works</ListItemText>
            <Divider />
          </ListItem>
          <ListItem
            button
            component={Link}
            to="/Talks"
            onClick={() => setOpenDrawer(false)}
          >
            <ListItemText>Talks</ListItemText>
            <Divider />
          </ListItem>
          <ListItem
            button
            component={Link}
            to="/CV"
            onClick={() => setOpenDrawer(false)}
          >
            <ListItemText>CV and Contacts</ListItemText>
            <Divider />
          </ListItem>
        </List>
      </Drawer>
      <IconButton onClick={() => setOpenDrawer(!openDrawer)}>
        <MenuIcon />
      </IconButton>
    </Fragment>
  );
};

export default DrawerBar;
