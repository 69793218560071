import classes from "./Layout.module.css";
import NavBar from "./NavBar";
import Drawer from "./Drawer";
import { useTheme, useMediaQuery } from "@mui/material";

const Layout = (props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <div>
      <div className={classes.section1}>
        {isMobile ? <Drawer /> : <NavBar />}
      </div>
      <div className={classes.section2}>
        <div className={classes.main}>{props.children}</div>
      </div>
    </div>
  );
};

export default Layout;
